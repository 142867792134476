<template>
  <div></div>
</template>

<script>
import eventBus from "../../components/Helpers/eventBus";

export default {
  name: "Logout",
  created () {
    this.$store.dispatch("logoutUser")
      .then(() => {
        eventBus.$emit("userLoggedOut");
        this.$router.push({
          name: "Home",
        });
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log(err);
        this.$forceUpdate();
      });
  },
};
</script>

<style>

</style>